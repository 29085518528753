import React from "react";
import { Container } from 'components/library'
import { UseCases as UseCasesHead } from "components/pages/solutions/use-cases";
import { graphql } from 'gatsby';
import DriveCollaboration from 'images/illus_eliminate_silos.png'
import DataDrivenForecast from 'images/illus_forecast_resource.png'
import EnhanceProductivity from 'images/illus_drive_productivity.png'
import { MiniNavbar } from "components/common/mini-navbar";
import { ResultsBlock } from "components/pages/solutions/results-block";
import { RndPageHero } from "components/pages/solutions/rnd/rnd-hero";
import { RndUseCases as UseCaseBody } from "components/pages/solutions/rnd/rnd-use-cases"
import { WhyPolymerize, WhyPolymerizeProps } from "components/pages/solutions/why-polymerize/why-polymerize";

const whyPolymerizeData: WhyPolymerizeProps['data'] = [
  {
    label: 'rnd-page.why-polymerize.reasons.1.label',
    heading: 'rnd-page.why-polymerize.reasons.1.heading',
    subheading: 'rnd-page.why-polymerize.reasons.1.subheading',
    image: DriveCollaboration
  },
  {
    label: 'rnd-page.why-polymerize.reasons.2.label',
    heading: 'rnd-page.why-polymerize.reasons.2.heading',
    subheading: 'rnd-page.why-polymerize.reasons.2.subheading',
    image: DataDrivenForecast
  },
  {
    label: 'rnd-page.why-polymerize.reasons.3.label',
    heading: 'rnd-page.why-polymerize.reasons.3.heading',
    subheading: 'rnd-page.why-polymerize.reasons.3.subheading',
    image: EnhanceProductivity
  },
]

const ProductPage = () => {
  return (
    <main>
      <Container className="flex flex-col gap-28 py-16">
        <MiniNavbar
          className='hidden sm:flex'
          title='common.words.rnd-operations'
          links={[
            { label: 'common.words.use-cases', path: '#use-cases' },
            { label: 'common.words.why-polymerize', path: '#why-polymerize' },
            { label: 'common.words.customers', path: '#customers' },
          ]}
        />

        <RndPageHero />

        <UseCasesHead
          title="rnd-page.use-cases.title"
          subtitle="rnd-page.use-cases.subtitle"
        />
        <UseCaseBody />

        <WhyPolymerize
          heading='rnd-page.why-polymerize.heading'
          subheading='rnd-page.why-polymerize.subheading'
          data={whyPolymerizeData}
        />
      </Container>

      <ResultsBlock />
    </main>
  );
};

export default ProductPage;

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`; 