import React from "react"
import Panel1Image from "images/illus_icon_project_mgmt.png"
import Panel2Image from "images/illus_icon_data_analysis.png"
import ss_soln_ops_dp from "images/ss_soln_ops_dp.png"
import ss_soln_ops_analyse from "images/ss_soln_ops_analyse.png"
import ss_soln_ops_report from "images/ss_soln_ops_report.png"
import ss_soln_ops_projmgmt from "images/ss_soln_ops_projmgmt.png"
import ss_soln_ops_user from "images/ss_soln_ops_user.png"
import ss_soln_ops_work_orders from "images/ss_soln_ops_work_orders.png"
import ss_soln_ops_notebook from "images/ss_soln_ops_notebook.png"
import { UseCasesBody, UseCasesBodyProps } from "../use-cases-body"

const projManPoints: UseCasesBodyProps["panel1"]["points"] = [
	{
		title: "rnd-page.use-cases.panels.1.points.1.title",
		desc: "rnd-page.use-cases.panels.1.points.1.desc",
		image: ss_soln_ops_projmgmt,
	},
	{
		title: "rnd-page.use-cases.panels.1.points.2.title",
		desc: "rnd-page.use-cases.panels.1.points.2.desc",
		image: ss_soln_ops_user,
	},
	{
		title: "rnd-page.use-cases.panels.1.points.3.title",
		desc: "rnd-page.use-cases.panels.1.points.3.desc",
		image: ss_soln_ops_work_orders,
	},
	{
		title: "rnd-page.use-cases.panels.1.points.4.title",
		desc: "rnd-page.use-cases.panels.1.points.4.desc",
		image: ss_soln_ops_notebook,
	},
]

const dataAnaPoints: UseCasesBodyProps["panel1"]["points"] = [
	{
		title: "rnd-page.use-cases.panels.2.points.1.title",
		desc: "rnd-page.use-cases.panels.2.points.1.desc",
		image: ss_soln_ops_dp,
	},
	{
		title: "rnd-page.use-cases.panels.2.points.2.title",
		desc: "rnd-page.use-cases.panels.2.points.2.desc",
		image: ss_soln_ops_analyse,
		descriptioni18: {
			components: {
				anchor: <a className="anchor" />,
			},
		},
	},
	{
		title: "rnd-page.use-cases.panels.2.points.3.title",
		desc: "rnd-page.use-cases.panels.2.points.3.desc",
		image: ss_soln_ops_report,
	},
]

export const RndUseCases = () => {
	return (
		<UseCasesBody
			panel1={{
				title: "common.words.project-management",
				description: "rnd-page.use-cases.panels.1.description",
				image: Panel1Image,
				points: projManPoints,
			}}
			panel2={{
				title: "common.words.data-analysis",
				description: "rnd-page.use-cases.panels.2.description",
				image: Panel2Image,
				points: dataAnaPoints,
			}}
		/>
	)
}
